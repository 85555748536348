import React from 'react';
import ReactSelect, { Props } from 'react-select';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import './select.scss';

type SizeProps = { size?: 'sm' | 'md' | 'lg' };

const Select: React.FC<Props & SizeProps> = ({ size = 'md', ...props }) => {
    const iconSize = size == 'sm' ? '18px' : size === 'md' ? '20px' : '24px';
    return (
        <ReactSelect
            {...props}
            id="select"
            classNames={{
                control: () => 'select-control ' + size,
                container: () => 'select-container ' + size,
                menu: () => 'select-menu ' + size,
                option: () => 'select-option ' + size,
                input: () => 'select-input ' + size,
                menuList: () => 'select-menu-list ' + size,
                singleValue: () => 'select-single-value ' + size,
                valueContainer: () => 'select-value-container ' + size,
                indicatorsContainer: () => 'select-indicators-container ' + size,
            }}
            components={{
                IndicatorSeparator: null,
                DropdownIndicator: () => <ChevronDown width={iconSize} height={iconSize} />,
            }}
        />
    );
};

export default Select;

export type SelectValue<T> = {
    value: T;
    label: string;
};
