import { Visualization } from 'components/Visualizer/visualization';
import { EquipmentDataManager } from './equipmentDataManager';
import { TrainDataManager } from './trainDataManager';
import { UsersDataManager } from './usersDataManager';
import { MetadataEquipments } from '_types/features';

export class LiveDataManager {
    viz: Visualization;
    dataManagers: {
        equipment: EquipmentDataManager;
        train: TrainDataManager;
        users: UsersDataManager;
    };

    pollInterval = 200;

    constructor(viz: Visualization, equipments: MetadataEquipments, site_id: string) {
        this.viz = viz;

        this.dataManagers = {
            equipment: new EquipmentDataManager(equipments),
            train: new TrainDataManager(),
            users: new UsersDataManager(site_id),
        };
    }

    public SetSubscriptions(pollInterval = this.pollInterval) {
        Object.values(this.dataManagers).forEach((dm) =>
            dm?.SetMainViewSubscription(this.viz, pollInterval)
        );
    }

    public StopSubscription() {
        Object.values(this.dataManagers).forEach((dm) => dm?.StopMainViewSubscription());
    }
}

export class LDM {
    viz: Visualization;

    SetMainViewSubscription: (viz: Visualization, pollInterval: number) => void;
    StopMainViewSubscription: () => void;
}
