import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { SobelOperatorShader } from 'three/examples/jsm/shaders/SobelOperatorShader';
// import { StaticAssets } from './viz_staticAssets'
import Stats from 'three/examples/jsm/libs/stats.module';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';

const stats = Stats();
// document.body.appendChild(stats.dom);

export class Viewport {
    resolution: THREE.Vector2;
    container: HTMLElement;
    renderer: THREE.WebGLRenderer;
    css3DRenderer: CSS3DRenderer;
    scene: THREE.Scene;
    clock: THREE.Clock;
    deltaTime: number;
    time: number;

    currentTime: number;
    lookBackInTime: number;
    defaultSize: number;
    structMaterial: THREE.MeshPhysicalMaterial;

    loaders: {
        gltf: GLTFLoader;
        fbx: FBXLoader;
        hdri: RGBELoader;
        font: FontLoader;
    };

    // staticModel: GLTF;
    composer: EffectComposer;

    renderPasses: {
        main: RenderPass;
        outlinePass: OutlinePass;
        innerOutLinePass: OutlinePass;
        bloomPass: UnrealBloomPass;
    };

    shaderPasses: {
        sobelShader: ShaderPass;
    };

    camera: THREE.PerspectiveCamera;
    controls: OrbitControls;

    lights: {
        cameraSpot: THREE.SpotLight;
        keyLight: THREE.DirectionalLight;
        fillLight: THREE.DirectionalLight;
    };

    fontSettings: {
        font?: string;
        size: number;
        height: number;
        curveSegments: number;
        bevelEnabled: true;
        bevelThickness: number;
        bevelSize: number;
        bevelOffset: number;
        bevelSegments: number;
    };

    updates: Array<() => void>;

    API: {
        lightProbeIntensity: number;
        directionalLightIntensity: number;
        envMapIntensity: number;
    };

    cameraData: {
        posX: number;
        posY: number;
        posZ: number;
    };

    cameraSettings: {
        fov: number;
        near: number;
        far: number;
    };

    //use for adding object references to viewport
    dependencies: Map<string, object>;

    constructor(element: HTMLDivElement, width: number, height: number) {
        this.container = element;
        this.resolution = new THREE.Vector2(width, height);

        this.css3DRenderer = new CSS3DRenderer();
        this.css3DRenderer.setSize(width, height);
        this.css3DRenderer.domElement.style.position = 'absolute';
        this.css3DRenderer.domElement.style.top = '2rem';
        this.container.appendChild(this.css3DRenderer.domElement);

        this.renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });
        this.renderer.setSize(width, height);
        this.container.appendChild(this.renderer.domElement);

        this.scene = new THREE.Scene();
        this.scene.background = null;
        //(this.scene.background as THREE.Color) = new THREE.Color('rgb(0,128,255)');

        //these types aren't defined for ts
        this.renderer.sortObjects = false;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.toneMapping = THREE.LinearToneMapping;
        this.renderer.toneMappingExposure = 1;
        this.renderer.outputEncoding = THREE.sRGBEncoding;

        this.loaders = {
            gltf: new GLTFLoader(),
            fbx: new FBXLoader(),
            hdri: new RGBELoader(),
            font: new FontLoader(),
        };
        this.cameraData = {
            posX: 5,
            posY: 2.5,
            posZ: 5,
        };

        this.cameraSettings = {
            fov: 75,
            near: 0.1,
            far: 10000,
        };

        this.camera = new THREE.PerspectiveCamera(
            this.cameraSettings.fov,
            width / height,
            this.cameraSettings.near,
            this.cameraSettings.far
        );
        this.camera.position.set(this.cameraData.posX, this.cameraData.posY, this.cameraData.posZ);
        this.controls = new OrbitControls(this.camera, this.css3DRenderer.domElement);
        this.controls.target.set(0, 0, 0);
        this.controls.update();

        const renderTargetParams = {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            stencilBuffer: false,
        };
        const renderTarget = new THREE.WebGLRenderTarget(width, height, renderTargetParams);
        this.composer = new EffectComposer(this.renderer, renderTarget);

        this.shaderPasses = {
            sobelShader: new ShaderPass(SobelOperatorShader),
        };
        this.structMaterial = new THREE.MeshPhysicalMaterial({
            color: 0x4f9fdd,
            wireframe: false,
            transmission: 0.148,
            transparent: true,
            envMap: this.scene.environment,
            opacity: 0.15,
            metalness: 0.2,
            roughness: 0.8,
            ior: 1.5,
            specularIntensity: 0.1,
            envMapIntensity: 0.06,
            side: THREE.DoubleSide,
            depthWrite: false,
        });

        this.renderPasses = {
            main: new RenderPass(this.scene, this.camera),
            outlinePass: new OutlinePass(this.resolution, this.scene, this.camera),
            innerOutLinePass: new OutlinePass(this.resolution, this.scene, this.camera),
            bloomPass: new UnrealBloomPass(new THREE.Vector2(width, height), 0.1, 0.1, 0.1),
        };
        this.renderPasses.main.clearColor = new THREE.Color(0, 1, 1);
        this.renderPasses.main.clearAlpha = 0.0;
        this.composer.addPass(this.renderPasses.main);
        this.composer.addPass(this.renderPasses.outlinePass);
        this.composer.addPass(this.renderPasses.innerOutLinePass);
        //this.composer.addPass(this.renderPasses.bloomPass);
        // this.composer.addPass(this.shaderPasses.sobelShader);

        this.shaderPasses.sobelShader.uniforms['resolution'].value.x =
            innerWidth * window.devicePixelRatio;
        this.shaderPasses.sobelShader.uniforms['resolution'].value.y =
            innerHeight * window.devicePixelRatio;
        this.shaderPasses.sobelShader.enabled = false;

        this.renderPasses.outlinePass.edgeStrength = 1;
        this.renderPasses.outlinePass.edgeGlow = 3;
        this.renderPasses.outlinePass.edgeThickness = 2;
        this.renderPasses.outlinePass.visibleEdgeColor = new THREE.Color(1, 1, 1);
        this.renderPasses.outlinePass.hiddenEdgeColor = new THREE.Color(0.8, 0.8, 0.8);
        this.renderPasses.outlinePass.usePatternTexture = false;
        this.renderPasses.outlinePass.downSampleRatio = 2;
        this.renderPasses.outlinePass.pulsePeriod = 0;

        this.renderPasses.innerOutLinePass.edgeStrength = 1;
        this.renderPasses.innerOutLinePass.edgeGlow = 3;
        this.renderPasses.innerOutLinePass.edgeThickness = 2;
        this.renderPasses.innerOutLinePass.visibleEdgeColor = new THREE.Color(1, 1, 1);
        this.renderPasses.innerOutLinePass.hiddenEdgeColor = new THREE.Color(0.8, 0.8, 0.8);
        this.renderPasses.innerOutLinePass.usePatternTexture = false;
        this.renderPasses.innerOutLinePass.downSampleRatio = 2;
        this.renderPasses.innerOutLinePass.pulsePeriod = 0;

        this.lights = {
            cameraSpot: new THREE.SpotLight(),
            keyLight: new THREE.DirectionalLight(),
            fillLight: new THREE.DirectionalLight(),
        };
        this.lights.cameraSpot.position.set(0, 0, 0);
        this.lights.cameraSpot.intensity = 1;
        this.lights.cameraSpot.castShadow = true;
        this.lights.cameraSpot.penumbra = 0.2;
        this.lights.cameraSpot.decay = 0.1;
        this.lights.cameraSpot.name = 'CameraSpot';
        this.lights.cameraSpot.target = new THREE.Object3D();
        this.lights.cameraSpot.target.name = 'spotLightTarget';
        this.scene.add(this.lights.cameraSpot);
        this.scene.add(this.lights.cameraSpot.target);

        // const axesHelper = new THREE.AxesHelper(50);
        // this.scene.add(axesHelper);

        this.fontSettings = {
            font: undefined,
            size: 0.05,
            height: 0.0025,
            curveSegments: 12,
            bevelEnabled: true,
            bevelThickness: 0.001,
            bevelSize: 0.001,
            bevelOffset: 0,
            bevelSegments: 5,
        };

        this.API = {
            lightProbeIntensity: 1.0,
            directionalLightIntensity: 0.6,
            envMapIntensity: 1,
        };

        this.currentTime = 0;
        this.lookBackInTime = 1;
        this.defaultSize = 500;

        this.updates = new Array<() => void>();

        //start the timer
        this.time = 0;

        this.deltaTime = 0;
        this.clock = new THREE.Clock();
        this.clock.start();

        //start the game loop
        this.loop();

        this.dependencies = new Map<string, object>();
    }

    public setSize(width, height) {
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
        this.renderer.domElement.width = width;
        this.renderer.domElement.height = height;

        this.css3DRenderer.setSize(width, height);
        this.css3DRenderer.domElement.style.width = width + 'px';
        this.css3DRenderer.domElement.style.height = height + 'px';
    }

    public loop = () => {
        requestAnimationFrame(this.loop);
        this.deltaTime = this.clock.getDelta();
        this.time = this.clock.elapsedTime;
        this.update();
        this.controls.update();
        this.render();

        stats.update();
    };

    public render = () => {
        this.renderer.render(this.scene, this.camera);
        this.css3DRenderer.render(this.scene, this.camera);
        //this.composer.render();
        //console.log('Number of triangles: ', this.renderer.info.render.triangles);
    };

    public update = () => {
        if (this.lights.cameraSpot != undefined) {
            this.lights.cameraSpot.target.position.copy(this.controls.target);
            this.lights.cameraSpot.position.copy(this.camera.position);
        }
        this.deltaTime = this.clock.getDelta();
        this.time = this.clock.elapsedTime;

        this.updates.forEach((callback) => {
            callback();
        });
    };
}
