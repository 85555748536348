import React from 'react';

import { ReactComponent as RightDownArrow } from '../../assets/icons/arrow-rightdown.svg';
import { ReactComponent as RightUpArrow } from '../../assets/icons/arrow-rightup.svg';
import { useQuery } from '@apollo/client';
import { GETPEOPLEONPLATFORM, GETTOTALCOUNTSINOUT } from '_queries';
import { PeopleOnPlatformType, PeopleTotalCountData } from '_types/queries';

const LiveComponents: React.FC = () => {
    const {
        data: tcData,
        startPolling: tcStartPolling,
        stopPolling: tcStopPolling,
    } = useQuery<PeopleTotalCountData>(GETTOTALCOUNTSINOUT);
    const {
        data: peopleOnPlatformData,
        startPolling: peopleOnPlatformStartPolling,
        stopPolling: peopleOnPlatformStopPolling,
    } = useQuery<PeopleOnPlatformType>(GETPEOPLEONPLATFORM);

    React.useEffect(() => {
        tcStartPolling(500);
        peopleOnPlatformStartPolling(2000);

        return () => {
            tcStopPolling();
            peopleOnPlatformStopPolling();
        };
    }, []);

    return (
        <div className="live-stats stats-group">
            <div className="platform-stats stats-data">
                <label htmlFor="platform-stats">Platform stats</label>
                <div className="occupant-counts data-group station-overview-data-group">
                    <label htmlFor="occupant-counts">Total passengers</label>
                    <div className="station-overview-data">
                        <span className="incoming">
                            <RightDownArrow />
                            {tcData?.getTotalCountsInOut[0].incoming_people_count ?? '--'}
                        </span>
                        <span className="outgoing">
                            {tcData?.getTotalCountsInOut[0].outgoing_people_count ?? '--'}
                            <RightUpArrow />
                        </span>
                    </div>
                </div>
                <div className="data-group">
                    <div className="data">
                        <label>People on platform</label>
                        <span>
                            {peopleOnPlatformData?.getPlatformCount[0].platform_count ?? '--'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveComponents;
