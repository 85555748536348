import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AlertsQuery, EscaltorAlertQuery } from '_types/queries';
import { AlertsPanel, AlertsPanelWithId } from '_types';
import { VizStore } from 'components/Visualizer/Visualizer';

export interface AlertsSliceState {
    alerts: EscaltorAlertQuery[];
    toRead: boolean;
    alertsPanels: Record<string, AlertsPanel>;
}

const initialState: AlertsSliceState = {
    alerts: [],
    toRead: false,
    alertsPanels: {},
};

/*===============================
              Slice
===============================*/

export const SimulationViewSlice = createSlice({
    name: 'alertsView',
    initialState,
    reducers: {
        setAlerts: (state, action: PayloadAction<EscaltorAlertQuery[]>) => {
            state.alerts = action.payload;
            state.toRead = !!action.payload.find(
                (alert) => alert.read_status === "unread"
            );
        },
        checkAlertsToRead: (state) => {
            state.toRead = !state.alerts.find((alert) => alert.read_status);
        },
        setAlertPanel: (state, action: PayloadAction<AlertsPanelWithId | undefined>) => {
            if (action.payload) {
                const panel: AlertsPanelWithId = action.payload;
                state.alertsPanels[panel.id] = { ...panel };
            }

            VizStore.setAlertsPanels(current(state.alertsPanels));
        },
        deleteAlertPanel: (state, action: PayloadAction<string>) => {
            delete state.alertsPanels[action.payload];

            VizStore.setAlertsPanels(state.alertsPanels);
        },
    },
});

/*===============================
            Actions
===============================*/

export const { setAlerts, checkAlertsToRead, setAlertPanel, deleteAlertPanel } =
    SimulationViewSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectAlerts = (state: RootState) => state.alertsSlice.alerts;
export const selectAlertsToRead = (state: RootState) => state.alertsSlice.toRead;

export default SimulationViewSlice.reducer;
