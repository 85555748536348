import React, { useEffect } from 'react';
import FloorNavigator from 'components/FloorNavigator';
import { useAppSelector } from 'app/hooks';
import Visualizer, { VizStore } from 'components/Visualizer/Visualizer';
import '../stationTwin.scss';
import { selectCurrentCameraView } from 'features/StationView/StationViewSlice';
import { useQuery } from '@apollo/client';
import {
    PeopleTotalCountData,
    PowerConsumptionQuery,
    EscalatorPeopleUsage,
    ServiceOrderInfoData,
} from '_types/queries';
import {
    GETPOWERCONSUMPTION,
    GETTOTALCOUNTSINOUT,
    GETESCALATORPEOPLEUSAGE,
    GETSERVICEORDERVISITINFO,
} from '_queries';
import GaugeGraph from 'components/Graphs/GaugeGraph';
import { useGetDigiTwinUIAPIQuery } from 'app/store';
import moment from 'moment';
import { LiveDataManager } from 'dataManagers/liveDatamanager/liveDataManager';
import { useRoute } from 'wouter';

const LiveView: React.FC = () => {
    const [, params] = useRoute('/stations/:stationID');
    const stationID = params?.stationID ?? '';

    const activeView = useAppSelector(selectCurrentCameraView);

    const equipmentsQuery = useGetDigiTwinUIAPIQuery('stations/' + stationID + '/equipments');

    useEffect(() => {
        if (equipmentsQuery.status !== 'fulfilled') return;

        const viz = VizStore.getInstance().visualization;
        const liveDataManager = new LiveDataManager(
            viz,
            equipmentsQuery.data.equipments,
            stationID
        );
        liveDataManager.SetSubscriptions(200);

        return () => liveDataManager.StopSubscription();
    }, [equipmentsQuery, stationID]);

    return (
        <>
            <div className="top-left-corner">
                {activeView.key.includes('elevator') ? (
                    <ElevatorStats
                        label={activeView.label}
                        id={
                            equipmentsQuery?.data?.equipments.elevators.find((esc) =>
                                esc.name.includes(activeView.label)
                            )?.id
                        }
                    />
                ) : activeView.key.includes('escalator') ? (
                    <EscalatorStats
                        label={activeView.label}
                        id={
                            equipmentsQuery?.data?.equipments.escalators.find((esc) =>
                                esc.name.includes(activeView.label)
                            )?.id
                        }
                    />
                ) : null}
            </div>

            <FloorNavigator />

            <Visualizer stationID={stationID} />
        </>
    );
};

export default LiveView;

const ElevatorStats: React.FC<{ label: string; id?: string }> = ({ label, id }) => {
    const {
        data: tcData,
        startPolling: tcStartPolling,
        stopPolling: tcStopPolling,
    } = useQuery<PeopleTotalCountData>(GETTOTALCOUNTSINOUT);

    const queryServiceTechVisit = useQuery<ServiceOrderInfoData>(GETSERVICEORDERVISITINFO);

    React.useEffect(() => {
        tcStartPolling(500);
        queryServiceTechVisit.startPolling(500);

        return () => {
            tcStopPolling();
            queryServiceTechVisit.stopPolling();
        };
    }, []);

    const [serviceTechVisit, setServiceTechVisit] = React.useState<ServiceTechVisit[]>();

    React.useEffect(() => {
        if (!queryServiceTechVisit.data) return;

        const data =
            label == 'AE01-2'
                ? queryServiceTechVisit.data.getServiceOrderVisitInfo.filter(
                      (ord) =>
                          ord.equipmentNumber == '11292263' || ord.equipmentNumber == '11292264'
                  )
                : queryServiceTechVisit.data.getServiceOrderVisitInfo.filter(
                      (ord) => ord.equipmentNumber == id
                  );

        if (data && data.length > 0) {
            setServiceTechVisit(
                data.map((el) => ({
                    date: new Date(
                        el.sch_maint_date.includes('(')
                            ? el.sch_maint_date.split('(')[1].split(')')[0]
                            : el.sch_maint_date
                    ),
                    orderNumber: el.serviceOrderNumber,
                    status: el.status,
                    type: el.type,
                }))
            );
        } else setServiceTechVisit(undefined);
    }, [label, id, queryServiceTechVisit.data]);

    return (
        <div className="equipment-stats">
            <label htmlFor="equipment-stats">{label}</label>
            <ul>
                <li>
                    <span className="stat">Users:</span>
                    <span>
                        {tcData?.getTotalCountsInOut[0][label.replace('-', '_') + '_people'] ??
                            '--'}
                    </span>
                </li>
                <li>
                    <span className="stat">Bike users:</span>
                    <span>
                        {tcData?.getTotalCountsInOut[0][label.replace('-', '_') + '_bike'] ?? '--'}
                    </span>
                </li>
                {queryServiceTechVisit.data && serviceTechVisit && (
                    <li>
                        <div>
                            <span className="stat">Technician visits:</span>
                            {serviceTechVisit?.map((visitInfo, idx) => (
                                <TechVisitPanel
                                    visitInfo={visitInfo}
                                    key={visitInfo.orderNumber}
                                    divider={idx !== 0}
                                />
                            ))}
                        </div>
                    </li>
                )}
            </ul>
        </div>
    );
};

const EscalatorStats: React.FC<{ label: string; id?: string }> = ({ label, id }) => {
    // Query Escalator Power Data
    const {
        data: powerData,
        startPolling: startPollingPower,
        stopPolling: stopPollingPower,
    } = useQuery<PowerConsumptionQuery>(GETPOWERCONSUMPTION);

    // Query People Usage Data
    const {
        data: peopleUsageData,
        startPolling: startPollingPeopleUsage,
        stopPolling: stopPollingPeopleUsage,
    } = useQuery<EscalatorPeopleUsage>(GETESCALATORPEOPLEUSAGE);

    // Query service technician visit data
    const queryServiceTechVisit = useQuery<ServiceOrderInfoData>(GETSERVICEORDERVISITINFO);

    React.useEffect(() => {
        startPollingPower(500);
        startPollingPeopleUsage(500);
        queryServiceTechVisit.startPolling(500);

        return () => {
            stopPollingPower();
            stopPollingPeopleUsage();
            queryServiceTechVisit.stopPolling();
        };
    }, [label]);

    const [serviceTechVisit, setServiceTechVisit] = React.useState<ServiceTechVisit[]>();

    React.useEffect(() => {
        if (!queryServiceTechVisit.data || !id) return;

        const data = queryServiceTechVisit.data.getServiceOrderVisitInfo.filter(
            (ord) => ord.equipmentNumber == id
        );

        if (data && data.length > 0) {
            setServiceTechVisit(
                data.map((el) => ({
                    date: new Date(
                        el.sch_maint_date.includes('(')
                            ? el.sch_maint_date.split('(')[1].split(')')[0]
                            : el.sch_maint_date
                    ),
                    orderNumber: el.serviceOrderNumber,
                    status: el.status,
                    type: el.type,
                }))
            );
        }

        console.log(serviceTechVisit, data);
    }, [label, id, queryServiceTechVisit.data]);

    return (
        <div className="equipment-stats">
            <label htmlFor="equipment-stats">{label}</label>

            <ul>
                <li>
                    <span className="stat">Users:</span>
                    <span>
                        {peopleUsageData?.listEscalatorUsageData[0][label + '_people'] ?? '--'}
                    </span>
                </li>
                <li>
                    <span className="stat">Bike users:</span>
                    <span>
                        {peopleUsageData?.listEscalatorUsageData[0][label + '_bike'] ?? '--'}
                    </span>
                </li>
                <li>
                    <GaugeGraph
                        title={'Estimated Power usage:'}
                        style={{ width: '160px', height: '170px' }}
                        lineWidth={6}
                        fontSize={12}
                        valueLabel=" kW"
                        max={5}
                        tickDistance={-45}
                        value={
                            Math.floor(
                                powerData?.getLiveEnergyData.items.find(
                                    (power) => power.id === label
                                )?.power_consumed ?? 0
                            ) / 1000
                        }
                        colors={[[1, 'white']]}
                    />
                </li>
                {queryServiceTechVisit.data && serviceTechVisit && (
                    <li>
                        <div>
                            <span className="stat">Technician visits:</span>
                            {serviceTechVisit?.map((visitInfo, idx) => (
                                <TechVisitPanel
                                    visitInfo={visitInfo}
                                    key={visitInfo.orderNumber}
                                    divider={idx !== 0}
                                />
                            ))}
                        </div>
                    </li>
                )}
            </ul>
        </div>
    );
};

type ServiceTechVisit = { date: Date; orderNumber: string; status: string; type: string };

const TechVisitPanel: React.FC<{ visitInfo: ServiceTechVisit; divider: boolean }> = ({
    visitInfo,
    divider,
}) => {
    return (
        <ul className={`visit-info-panel ${divider ? 'visit-info-panel-divider' : ''}`}>
            <li>{'Service Order: ' + visitInfo.orderNumber}</li>
            <li>{'Type: ' + visitInfo.type}</li>
            <li>{'Status: ' + visitInfo.status}</li>
            <li>{'Date: ' + moment(visitInfo.date).format('DD-MM-YYYY')}</li>
        </ul>
    );
};
