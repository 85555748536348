import React from 'react';
import './segmentControl.scss';
import { capitalize } from 'components/util';

type SizeType = 'sm' | 'md' | 'lg';

type Props = {
    options: string[];
    eOnChange: (value: string) => void;
    size?: SizeType;
    alerts?: string[];
    active?: string;
};

const SegmentControl: React.FC<Props> = (props) => {
    const { options, eOnChange, active, size = 'md' } = props;
    return (
        <div className={'segment-control ' + size}>
            {options.map((option) => (
                <SegmentOption
                    active={active === option}
                    onClick={() => eOnChange(option)}
                    key={option}
                    size={size}>
                    {option}
                </SegmentOption>
            ))}
        </div>
    );
};

export default SegmentControl;

type OptionProps = React.PropsWithChildren<{
    active: boolean;
    onClick: () => void;
    size?: SizeType;
}>;

const SegmentOption: React.FC<OptionProps> = ({ active, onClick, children, size }) => {
    return (
        <div className={`segment-option ${active ? 'active' : ''} ${size}`} onClick={onClick}>
            {children && typeof children === 'string' && capitalize(children)}
        </div>
    );
};
