import { Visualization } from 'components/Visualizer/visualization';
import { PeopleLiveDataToPeopleDraw } from '../util';
import { LDM } from './liveDataManager';
import { PeopleDraw, PeopleLiveItem } from '_types';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { apolloClient } from 'app/clients/apolloClient';
import { Subscription } from '@apollo/client/node_modules/zen-observable-ts/module';

import { SUBSCRIBE_TO_PEOPLE_MOVEMENT } from '_queries';

interface OnPeopleMovementResponse {
    site_id: string;
    people: PeopleLiveItem[];
}

interface SubscriptionResponse {
    onPeopleMovement?: OnPeopleMovementResponse;
}

export class UsersDataManager implements LDM {
    viz: Visualization;
    events: PeopleDraw[][] = [];

    client: ApolloClient<NormalizedCacheObject>;
    site_id: string;
    peopleSubscription: Subscription;

    constructor(site_id) {
        this.site_id = site_id;

        this.client = apolloClient;
        this.events = [];
    }

    public SetMainViewSubscription(viz: Visualization) {
        this.viz = viz;

        // check if site_id is not an empty string, otherwise return
        if (this.site_id === '') {
            return;
        }

        const subscription = this.client
            .subscribe({
                query: SUBSCRIBE_TO_PEOPLE_MOVEMENT,
                variables: { siteId: this.site_id },
            })
            .subscribe({
                next: ({ data }: { data: SubscriptionResponse }) => {
                    const onPeopleMovement = data?.onPeopleMovement;
                    if (onPeopleMovement) {
                        const { people } = onPeopleMovement;
                        this.subscribeFunction.bind(this)(people);
                    } else {
                        console.warn('No data received or onPeopleMovement is null.');
                    }
                },
                error: (e) => console.error(e),
            });

        this.peopleSubscription = subscription;
    }

    private subscribeFunction(message: PeopleLiveItem[]) {
        this.events[0] = message.map((person) => PeopleLiveDataToPeopleDraw(person));

        this.viz.people.UpdateMeshes(this.events.flat());
    }

    public StopMainViewSubscription() {
        this.peopleSubscription?.unsubscribe();
        this.viz.people.HidePeople();
    }
}
