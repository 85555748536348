import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    digiTwinUIAPI,
    DigiTwinUIAPIQueryActionCreatorResult,
    RootState,
    store,
} from '../../app/store';

type MetroLine = {
    site_id: string;
    site_name: string;
    is_active: 0 | 1;
    order_number: number;
}[];

export interface MetroLineSliceState {
    metroLines: Record<string, MetroLine>;
    station_id: string | null;
}

const initialState: MetroLineSliceState = {
    metroLines: {},
    station_id: null,
};

/*===============================
             Thunks
===============================*/

export const addMetroline = createAsyncThunk(
    'MetroLine/addMetroline',
    async (metroLineId: string): Promise<[string, MetroLine]> => {
        const promise: DigiTwinUIAPIQueryActionCreatorResult<{ site_list: MetroLine }> =
            store.dispatch(
                digiTwinUIAPI.endpoints.getDigiTwinUIAPI.initiate(`/groups/${metroLineId}`)
            );
        const { data, error } = await promise;
        if (error) {
            console.error(error);
            throw new Error('Something went wrong');
        }
        return [metroLineId, data?.site_list as MetroLine];
    }
);

/*===============================
              Slice
===============================*/

export const MetroLinesSlice = createSlice({
    name: 'MetroLine',
    initialState,
    reducers: {
        setMetroLines: (state, action: PayloadAction<Record<string, MetroLine>>) => {
            state.metroLines = action.payload;
        },
        removeMetroLineById: (state, action: PayloadAction<string>) => {
            if (!state.metroLines[action.payload])
                console.error(`No metro lines found with id: ${action.payload}`);
            else delete state.metroLines[action.payload];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            addMetroline.fulfilled,
            (state, action: PayloadAction<[string, MetroLine]>) => {
                if (state.metroLines[action.payload[0]]) {
                    console.error('This metro line exists already');
                    return;
                }
                state.metroLines[action.payload[0]] = action.payload[1];
            }
        );
    },
});

/*===============================
            Actions
===============================*/

export const { setMetroLines } = MetroLinesSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectMetroLines = (state: RootState) => state.metroLineSlice.metroLines;

export default MetroLinesSlice.reducer;
